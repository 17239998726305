const courseMap = {
  "product-design": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Product Design",
          details: {
            title: "Module 1",
            description:
              "Introduce the essential concepts of product design and familiarize students with key design tools and methodologies.",
            list: [
              "What is product design?",
              "The role of a product designer in the product development lifecycle.",
              "Key design principles: usability, functionality, and aesthetics.",
              "Overview of design tools (Figma, Sketch, Adobe XD).",
              "Introduction to design thinking and human-centered design.",
              "Conduct a design audit of a digital product, evaluating its usability and design strengths/weaknesses.",
            ],
          },
        },
        {
          name: "User Research and Personas",
          details: {
            title: "Module 1",
            description:
              "Introduce the essential concepts of product design and familiarize students with key design tools and methodologies.",
            list: [
              "The importance of understanding user needs through research.",
              "Methods for gathering user insights: surveys, interviews, and focus groups.",
              "Creating user personas and user journeys.",
              "Conduct a user interview and analyze findings.",
              "Create user personas based on real-world data.",
              "Develop a user persona and user journey for a proposed product or feature.",
            ],
          },
        },
        {
          name: "Ideation and Brainstorming",
          details: {
            title: "Module 1",
            description:
              "Introduce the essential concepts of product design and familiarize students with key design tools and methodologies.",
            list: [
              "Techniques for ideation: brainstorming, mind mapping, and sketching.",
              "How to evaluate ideas and choose the best design direction.",
              "Rapid prototyping and iterative design.",
              "Engage in group brainstorming and idea validation exercises.",
              "Sketch wireframes for a basic product interface.",
              "Create a mood board and initial design concepts for a digital product.",
            ],
          },
        },
        {
          name: "Information Architecture and Wireframing",
          details: {
            title: "Module 1",
            description:
              "Introduce the essential concepts of product design and familiarize students with key design tools and methodologies.",
            list: [
              "The importance of information architecture in product design.",
              "How to organize content for user flow and navigation.",
              "The role of wireframing in building intuitive interfaces",
              "Create a site map and wireframe for a sample product.",
              "Explore how to create low-fidelity wireframes using Figma or Adobe XD.",
              "Quiz on information architecture principles and wireframing techniques.",
            ],
          },
        },
        {
          name: "Visual Design Principles",
          details: {
            title: "Module 2",
            description:
              "Develop proficiency in creating high-fidelity designs, prototyping, and conducting user testing.",
            list: [
              "Visual design principles: contrast, balance, hierarchy, and alignment.",
              "Color theory, typography, and iconography in product design.",
              "Designing with accessibility in mind.",
              "Design a high-fidelity interface for a feature of your product using Figma.",
              "Apply design principles to improve an existing design.",
              "Create a style guide for a digital product design, including typography, colors, and component libraries.",
            ],
          },
        },
        {
          name: "Interactive Prototyping",
          details: {
            title: "Module 2",
            description:
              "Develop proficiency in creating high-fidelity designs, prototyping, and conducting user testing.",
            list: [
              "The role of prototyping in testing design ideas.",
              "Low-fidelity vs. high-fidelity prototypes.",
              "Using tools like Figma or Adobe XD to create interactive prototypes.",
              "Build an interactive prototype for a mobile or web application.",
              "Learn how to link screens, add interactions, and simulate user flows.",
              "Create a clickable prototype and user flow for a proposed product feature.",
            ],
          },
        },
        {
          name: "Usability Testing and User Feedback",
          details: {
            title: "Module 2",
            description:
              "Develop proficiency in creating high-fidelity designs, prototyping, and conducting user testing.",
            list: [
              "The importance of user testing in product design.",
              "Different types of usability tests: formative, summative, A/B testing.",
              "How to analyze user feedback and iterate on designs.",
              "Conduct usability testing on your prototype with real users.",
              "Gather feedback and make design revisions based on testing.",
              "Write a usability testing report that outlines the results and suggested design improvements.",
            ],
          },
        },
        {
          name: "Design Handoff and Collaboration with Developers",
          details: {
            title: "Module 2",
            description:
              "Develop proficiency in creating high-fidelity designs, prototyping, and conducting user testing.",
            list: [
              "Best practices for handing off designs to development teams.",
              "How to use design systems and documentation to ensure smooth collaboration.",
              "Tools and communication strategies for working with developers.",
              "Create design documentation and handoff files for a development team.",
              "Learn how to use collaboration tools like Zeplin and Figma for design handoff.",
              "Prepare a design package for a mock product and collaborate with a developer to ensure proper implementation.",
            ],
          },
        },
        {
          name: "Designing for Mobile vs. Web",
          details: {
            title: "Module 3",
            description:
              "Apply learned skills to real-world projects, refine designs, and prepare for career opportunities.",
            list: [
              "The differences between designing for mobile apps and web applications.",
              "Mobile-first design principles and responsive design techniques.",
              "Designing for different screen sizes and resolutions.",
              "Create a mobile and desktop version of a product design, ensuring consistency across platforms.",
              "Design a responsive layout using Figma.",
              "Redesign a mobile app interface, focusing on user experience and responsiveness.",
            ],
          },
        },
        {
          name: "Portfolio Development and Case Studies",
          details: {
            title: "Module 3",
            description:
              "Apply learned skills to real-world projects, refine designs, and prepare for career opportunities.",
            list: [
              "How to create a compelling product design portfolio.",
              "Writing case studies: Documenting the design process from ideation to implementation.",
              "What to include in a design portfolio for job applications.",
              "Start building a personal design portfolio with at least two case studies.",
              "Create a case study that highlights your design process for a project completed in the course.",
              "Submit your first portfolio project and case study for feedback.",
            ],
          },
        },
        {
          name: "Career Development and Networking",
          details: {
            title: "Module 3",
            description:
              "Apply learned skills to real-world projects, refine designs, and prepare for career opportunities.",
            list: [
              "How to prepare for job interviews as a product designer.",
              "Building your network and connecting with design communities.",
              "Resume building and interview tips for product design roles.",
              "Mock interview with a design recruiter or mentor.",
              "Review and refine your design portfolio and resume.",
              "Complete a mock job application for a product design position and receive feedback.",
            ],
          },
        },
        {
          name: "Final Assessment and Graduation",
          details: {
            title: "Module 3",
            description:
              "Apply learned skills to real-world projects, refine designs, and prepare for career opportunities.",
            list: [
              "Graduation ceremony with career guidance (portfolio review, job prep, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are product designers earning?",
      subHeading:
        "Here are some average salary ranges for product designers in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦2M/year" },
        { name: "United Kingdom", value: "£48,000/year" },
        { name: "United States", value: "$95,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +11.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/3337ud",
  },
  "front-end-engineering": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Frontend Engineering",
          details: {
            title: "Module 1",
            description:
              "Introduce fundamental concepts, tools, and programming essentials for frontend development.",
            list: [
              "Role of a frontend engineer in web development.",
              "Overview of frontend vs. backend responsibilities.",
              "Introduction to web architecture and client-server models.",
              "Set up a development environment (VS Code, browser dev tools).",
              "Build a simple HTML/CSS web page.",
              "Brainstorm ideas for improving the user experience of a basic webpage.",
            ],
          },
        },
        {
          name: "HTML and CSS Fundamentals",
          details: {
            title: "Module 1",
            description:
              "Introduce fundamental concepts, tools, and programming essentials for frontend development.",
            list: [
              "Structure and semantics of HTML.",
              "CSS styling, layout techniques, and responsive design principles.",
              "Create a multipage website with navigation.",
              "Style the website using CSS (colors, fonts, and grids).",
              "Design a responsive landing page for a mock product or service.",
            ],
          },
        },
        {
          name: "JavaScript Basics",
          details: {
            title: "Module 1",
            description:
              "Introduce fundamental concepts, tools, and programming essentials for frontend development.",
            list: [
              "Introduction to JavaScript (variables, functions, loops, and events).",
              "DOM manipulation and event handling.",
              "Create an interactive form with real-time validation.",
              "Build a dynamic to-do list application.",
              "Develop a simple game (e.g., Rock-Paper-Scissors).",
            ],
          },
        },
        {
          name: "Responsive Design and Accessibility",
          details: {
            title: "Module 1",
            description:
              "Introduce fundamental concepts, tools, and programming essentials for frontend development.",
            list: [
              "Mobile-first design principles.",
              "Importance of web accessibility (WCAG guidelines).",
              "Refactor a project for responsiveness and accessibility.",
              "Test a website for accessibility using online tools.",
              "Quiz on HTML, CSS, and JavaScript basics.",
              "Complete a mini-project integrating the first month’s concepts.",
            ],
          },
        },
        {
          name: "Advanced JavaScript Concepts",
          details: {
            title: "Module 2",
            description:
              "Focus on modern frontend technologies, frameworks, and tools.",
            list: [
              "Asynchronous programming (Promises, async/await).",
              "Working with APIs (fetch and axios).",
              "Fetch data from a public API and display it dynamically.",
              "Build a weather app using API integration.",
            ],
          },
        },
        {
          name: "Frontend Frameworks (React.js)",
          details: {
            title: "Module 2",
            description:
              "Focus on modern frontend technologies, frameworks, and tools.",
            list: [
              "Introduction to React.js and component-based architecture.",
              "State management and props.",
              "Build a React-based app with reusable components.",
              "Create a simple shopping cart system.",
            ],
          },
        },
        {
          name: "Version Control and Deployment",
          details: {
            title: "Module 2",
            description:
              "Focus on modern frontend technologies, frameworks, and tools.",
            list: [
              "Introduction to Git and GitHub for version control.",
              "Overview of web hosting platforms (Netlify, Vercel).",
              "Collaborate on a project using Git and GitHub.",
              "Deploy a project to a live URL.",
            ],
          },
        },
        {
          name: "Testing and Debugging",
          details: {
            title: "Module 2",
            description:
              "Focus on modern frontend technologies, frameworks, and tools.",
            list: [
              "Debugging tools and techniques in modern browsers.",
              "Writing unit tests for frontend applications.",
              "Debug and fix errors in a sample project.",
              "Write test cases for a React component.",
              "Mid-term project: Build a dynamic, responsive React application.",
            ],
          },
        },
        {
          name: "Advanced Topics in Frontend Engineering",
          details: {
            title: "Module 3",
            description:
              "Dive into advanced topics, projects, and career preparation.",
            list: [
              "Introduction to state management tools (Redux, Context API).",
              "Performance optimization techniques.",
              "Implement global state management in a React app.",
              "Optimize a website’s performance using best practices.",
            ],
          },
        },
        {
          name: "Capstone Project Development",
          details: {
            title: "Module 3",
            description:
              "Dive into advanced topics, projects, and career preparation.",
            list: [
              "Teams brainstorm and finalize project ideas.",
              "Define milestones and assign roles.",
              "An interactive portfolio site.",
              "A movie or book recommendation app.",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Dive into advanced topics, projects, and career preparation.",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Dive into advanced topics, projects, and career preparation.",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are frontend developers earning?",
      subHeading:
        "Here are some average salary ranges for frontend developers in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£41,000/year" },
        { name: "United States", value: "$84,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +9.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/963kcs",
  },
  "back-end-engineering": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Backend Engineering",
          details: {
            title: "Module 1",
            description:
              "Introduce backend concepts, tools, and core programming fundamentals.",
            list: [
              "Role of a backend engineer in software development.",
              "Overview of backend vs. frontend architecture.",
              "Introduction to client-server models.",
              "Install and set up a backend environment (Node.js, Python Flask/Django, or Java Spring Boot).",
              "Create a simple 'Hello World' web server.",
              "Brainstorm the components of a basic web application.",
            ],
          },
        },
        {
          name: "Core Programming Concepts",
          details: {
            title: "Module 1",
            description:
              "Introduce backend concepts, tools, and core programming fundamentals.",
            list: [
              "Key programming concepts (variables, loops, functions, etc.).",
              "Overview of Object-Oriented Programming (OOP).",
              "Best practices for writing clean and maintainable code.",
              "Write basic scripts demonstrating OOP concepts.",
              "Create a simple function-based calculator.",
              "Solve coding problems on basic algorithms and data structures.",
            ],
          },
        },
        {
          name: "Databases (Relational and Non-Relational)",
          details: {
            title: "Module 1",
            description:
              "Introduce backend concepts, tools, and core programming fundamentals.",
            list: [
              "Introduction to databases (SQL and NoSQL).",
              "CRUD operations (Create, Read, Update, Delete).",
              "Relational database design and normalization.",
              "Set up a database (MySQL/PostgreSQL and MongoDB).",
              "Build tables and perform CRUD operations.",
              "Design a database schema for a mock e-commerce system.",
            ],
          },
        },
        {
          name: "Working with APIs (REST and GraphQL)",
          details: {
            title: "Module 1",
            description:
              "Introduce backend concepts, tools, and core programming fundamentals.",
            list: [
              "Understanding APIs and their role in backend systems.",
              "Difference between REST and GraphQL APIs.",
              "Build a RESTful API to perform basic CRUD operations.",
              "Create routes for a sample project (e.g., blog posts or user profiles).",
              "Quiz on concepts from the first month.",
              "Build a small project integrating a database with a REST API.",
              "Introduction to client-server models.",
            ],
          },
        },
        {
          name: "Authentication and Authorization",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into advanced backend topics and work on more complex tasks.",
            list: [
              "Understanding authentication vs. authorization.",
              "Implementing secure user login systems (JWT, OAuth).",
              "Build a user authentication system.",
              "Secure API endpoints with authentication.",
            ],
          },
        },
        {
          name: "Backend Frameworks and Libraries",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into advanced backend topics and work on more complex tasks.",
            list: [
              "Deep dive into backend frameworks (e.g., Express.js, Django, or Spring).",
              "Best practices for structuring scalable applications.",
              "Refactor previous projects using a backend framework.",
              "Add middleware for logging and error handling.",
            ],
          },
        },
        {
          name: "Working with Cloud Services",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into advanced backend topics and work on more complex tasks.",
            list: [
              "Introduction to cloud providers (AWS, Azure, GCP).",
              "Deploying backend applications.",
              "Deploy a simple backend application to the cloud.",
              "Store static files or data in cloud storage.",
            ],
          },
        },
        {
          name: "Debugging and Testing",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into advanced backend topics and work on more complex tasks.",
            list: [
              "Importance of testing in software development.",
              "Unit, integration, and end-to-end testing for backend applications.",
              "Write test cases using tools like Jest or PyTest.",
              "Debug an application with a mock bug injected.",
              "Mid-term project: Build a secure, scalable API integrated with a database and deploy it.",
            ],
          },
        },
        {
          name: "Advanced Topics in Backend Engineering",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and focus on career preparation, including a capstone project and final exam",
            list: [
              "Asynchronous programming and event-driven architecture.",
              "Introduction to microservices.",
              "Implement a simple microservice.",
              "Optimize an existing application for performance.",
            ],
          },
        },
        {
          name: "Capstone Project Development",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and focus on career preparation, including a capstone project and final exam",
            list: [
              "Teams brainstorm and finalize project ideas.",
              "Define milestones and assign roles.",
              "A task management tool.",
              "A restaurant reservation system.",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and focus on career preparation, including a capstone project and final exam",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and focus on career preparation, including a capstone project and final exam",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are backend developers earning?",
      subHeading:
        "Here are some average salary ranges for backend developers in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£47,000/year" },
        { name: "United States", value: "$89,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +9.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/3o6k33",
  },
  "technical-product-management": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Product Management",
          details: {
            title: "Module 1",
            description:
              "Introduce the fundamental concepts of technical product management and the product life cycle.",
            list: [
              "The role of a product manager vs. technical product manager.",
              "The product life cycle: Concept, Design, Development, Launch, and Post-Launch.",
              "Key responsibilities of a TPM: Prioritization, roadmap creation, and stakeholder management.",
              "Review and discuss real-world examples of technical product management.",
              "Define a product vision and initial requirements for a sample project.",
              "Brainstorm and outline a product roadmap for a hypothetical tech product.",
            ],
          },
        },
        {
          name: "Product Ideation and Validation",
          details: {
            title: "Module 1",
            description:
              "Introduce the fundamental concepts of technical product management and the product life cycle.",
            list: [
              "How to ideate, validate, and prioritize product features.",
              "Techniques for customer research, including surveys, interviews, and user testing.",
              "Defining Minimum Viable Product (MVP).",
              "Conduct a customer interview and define an MVP for a new product idea.",
              "Use frameworks like User Stories and Jobs to be Done (JTBD) to define product requirements.",
              "Create an MVP plan for a tech product idea.",
            ],
          },
        },
        {
          name: "Working with Cross-functional Teams",
          details: {
            title: "Module 1",
            description:
              "Introduce the fundamental concepts of technical product management and the product life cycle.",
            list: [
              "The role of a TPM in leading and coordinating cross-functional teams.",
              "Effective communication with design, engineering, and marketing teams.",
              "Handling conflict and aligning teams around product goals.",
              "Define roles and responsibilities for a cross-functional team working on a product.",
              "Develop a communication plan for managing team collaboration.",
              "Create a cross-functional project plan and communication strategy for a new product feature.",
            ],
          },
        },
        {
          name: "Product Roadmapping and Prioritization",
          details: {
            title: "Module 1",
            description:
              "Introduce the fundamental concepts of technical product management and the product life cycle.",
            list: [
              "Introduction to product roadmaps and how they guide product development.",
              "Techniques for prioritizing features, such as MoSCoW and RICE.",
              "Balancing business goals with technical constraints.",
              "Build a product roadmap for a sample product, using a roadmap tool (e.g., Aha!, ProductPlan).",
              "Prioritize features based on business value and technical feasibility.",
              "Quiz on product management concepts and prioritization frameworks.",
            ],
          },
        },
        {
          name: "Agile Methodology for Product Managers",
          details: {
            title: "Module 2",
            description:
              "Dive into advanced topics in technical product management and develop hands-on experience with industry tools.",
            list: [
              "Understanding Agile frameworks: Scrum, Kanban, and Lean.",
              "How product managers work within Agile teams.",
              "Writing effective user stories and acceptance criteria.",
              "Create user stories and sprint goals for a sample product feature.",
              "Participate in a mock sprint planning meeting.",
              "Develop a product backlog for a new product feature and manage it throughout a sprint cycle.",
            ],
          },
        },
        {
          name: "Managing Product Development and Iteration",
          details: {
            title: "Module 2",
            description:
              "Dive into advanced topics in technical product management and develop hands-on experience with industry tools.",
            list: [
              "How to manage product development and iterative improvements.",
              "Continuous delivery and integration in product development.",
              "Working with developers to ensure technical feasibility and scalability.",
              "Develop a product feature from start to finish, iterating on feedback from stakeholders.",
              "Work with development teams to adjust the product based on technical feedback.",
              "Refine the product’s development lifecycle and iterate on feedback.",
            ],
          },
        },
        {
          name: "Product Metrics and Performance Tracking",
          details: {
            title: "Module 2",
            description:
              "Dive into advanced topics in technical product management and develop hands-on experience with industry tools.",
            list: [
              "Key performance indicators (KPIs) for product success.",
              "How to track product performance using metrics such as user engagement, retention, and customer satisfaction.",
              "Using data to make informed product decisions.",
              "Set up product KPIs and track them using analytics tools (e.g., Google Analytics, Mixpanel).",
              "Analyze product performance and suggest improvements based on the data.",
              "Track the performance of a product feature and create a report with recommendations.",
            ],
          },
        },
        {
          name: "Risk Management and Handling Product Failures",
          details: {
            title: "Module 2",
            description:
              "Dive into advanced topics in technical product management and develop hands-on experience with industry tools.",
            list: [
              "Identifying and managing risks in product development.",
              "Learning from failures and managing product pivots.",
              "Techniques for managing product launches and post-launch reviews.",
              "Conduct a risk analysis for a product feature and create a mitigation plan.",
              "Develop a post-launch product review to analyze performance and gather feedback.",
              "Mid-term project: Define a risk management plan for a product feature and prepare a post-launch report.",
            ],
          },
        },
        {
          name: "Product Launch and Marketing Strategy",
          details: {
            title: "Module 3",
            description:
              "Focus on the real-world application of technical product management, capstone project, and career development.",
            list: [
              "The steps involved in preparing for a product launch.",
              "Collaborating with marketing teams to align product positioning and go-to-market strategy.",
              "Managing launch timelines and handling launch day issues.",
              "Develop a launch plan for a new product feature, including marketing collateral and stakeholder communication.",
              "Coordinate with marketing teams to define messaging and promotional strategies.",
            ],
          },
        },
        {
          name: "Capstone Project Development",
          details: {
            title: "Module 3",
            description:
              "Focus on the real-world application of technical product management, capstone project, and career development.",
            list: [
              "Teams finalize product ideas and define product requirements.",
              "Build a product roadmap and prepare for execution.",
              "Execute the project and apply Agile practices to manage development.",
              "Develop a SaaS application for a specific industry.",
              "Create a mobile app for a unique use case (e.g., fitness, education).",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Focus on the real-world application of technical product management, capstone project, and career development.",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Focus on the real-world application of technical product management, capstone project, and career development.",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are technical product managers earning?",
      subHeading:
        "Here are some average salary ranges for technical product manager in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£80,000/year" },
        { name: "United States", value: "$150,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +11.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/3gu54a",
  },
  "project-management": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Project Management",
          details: {
            title: "Module 1",
            description:
              "Introduce basic project management concepts, methodologies, and tools",
            list: [
              "What is project management?",
              "The project lifecycle: Initiation, Planning, Execution, Monitoring, and Closing.",
              "Key project management terms and concepts.",
              "Define a project and identify its scope, objectives, and stakeholders.",
              "Introduce project management tools (Trello, Asana, or Microsoft Project).",
              "Plan a simple project, such as organizing a virtual event.",
            ],
          },
        },
        {
          name: "Project Planning",
          details: {
            title: "Module 1",
            description:
              "Introduce basic project management concepts, methodologies, and tools",
            list: [
              "The importance of project planning.",
              "Setting SMART goals for projects.",
              "Work Breakdown Structure (WBS) and Gantt charts.",
              "Create a WBS for a sample project.",
              "Build a project timeline using Gantt charts.",
              "Develop a project plan for a case study, detailing project tasks, timelines, and resources.",
            ],
          },
        },
        {
          name: "Team Building and Communication",
          details: {
            title: "Module 1",
            description:
              "Introduce basic project management concepts, methodologies, and tools",
            list: [
              "The role of the project manager in team management.",
              "Effective communication in project teams.",
              "Conflict resolution and leadership styles.",
              "Define roles and responsibilities within a project team.",
              "Practice communication strategies for managing remote teams.",
              "Simulate a team meeting to discuss a project’s progress and resolve conflicts.",
            ],
          },
        },
        {
          name: "Risk Management and Problem Solvin",
          details: {
            title: "Module 1",
            description:
              "Introduce basic project management concepts, methodologies, and tools",
            list: [
              "Introduction to risk management.",
              "Identifying, assessing, and mitigating risks in projects.",
              "Problem-solving techniques and tools for project managers.",
              "Conduct a risk assessment for a sample project.",
              "Develop a risk management plan.",
              "Quiz on key project management concepts, including planning, communication, and risk management.",
            ],
          },
        },
        {
          name: "Project Execution and Resource Management",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into project execution, monitoring, and advanced project management tools",
            list: [
              "The execution phase of the project lifecycle.",
              "Resource management: human resources, budget, and materials.",
              "Delegating tasks and managing expectations.",
              "Create a resource allocation plan for a project.",
              "Develop a task delegation strategy and manage team progress.",
              "Manage the execution of a mock project, ensuring all resources are utilized efficiently.",
            ],
          },
        },
        {
          name: "Monitoring and Controlling Projects",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into project execution, monitoring, and advanced project management tools",
            list: [
              "Tracking project progress through Key Performance Indicators (KPIs).",
              "Using project management tools for task tracking and reporting.",
              "Managing project changes and scope creep.",
              "Track project milestones and update the project plan accordingly.",
              "Learn to adjust timelines and budgets when unexpected changes occur.",
              "Review a real-world project scenario and suggest ways to monitor and control the project.",
            ],
          },
        },
        {
          name: "Quality Management and Project Deliveryg",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into project execution, monitoring, and advanced project management tools",
            list: [
              "Quality assurance and control in project management.",
              "The role of project reviews and inspections.",
              "Closing the project: final deliverables and client handover.",
              "Develop a quality control plan for a project.",
              "Review project deliverables and prepare for project handover.",
              "Conduct a post-project review for a mock project, identifying areas of improvement.",
            ],
          },
        },
        {
          name: "Agile Methodology and Other Project Management Methodologies",
          details: {
            title: "Module 2",
            description:
              "Dive deeper into project execution, monitoring, and advanced project management tools",
            list: [
              "Introduction to Agile project management.",
              "Scrum, Kanban, and Lean methodologies.",
              "Benefits and challenges of Agile project management.",
              "Simulate an Agile project using Scrum sprints and standups.",
              "Learn to use Kanban boards for task management.",
              "Mid-term project: Manage an Agile project using Scrum or Kanban and report on progress.",
            ],
          },
        },
        {
          name: "Project Monitoring and Change Management",
          details: {
            title: "Module 3",
            description:
              "Work on a capstone project and focus on career development and real-world application of skills.",
            list: [
              "Handling changes during project execution (scope changes, timeline shifts).",
              "Change management strategies and tools.",
              "The impact of change on project delivery and stakeholder communication.",
              "Practice managing project changes and tracking the impact on overall goals.",
              "Use tools like Jira to track changes and progress.",
            ],
          },
        },
        {
          name: "Capstone Project Development",
          details: {
            title: "Module 3",
            description:
              "Work on a capstone project and focus on career development and real-world application of skills.",
            list: [
              "Teams brainstorm and finalize project ideas.",
              "Define project goals, deliverables, and timelines.",
              "Develop a comprehensive project plan using project management tools.",
              "Organizing a company event.",
              "Building a project management plan for a software launch.",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Work on a capstone project and focus on career development and real-world application of skills.",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Work on a capstone project and focus on career development and real-world application of skills.",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are project managers earning?",
      subHeading:
        "Here are some average salary ranges for project managers in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£49,000/year" },
        { name: "United States", value: "$88,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +9.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/i93472",
  },
  "data-science": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Data Science",
          details: {
            title: "Module 1",
            description:
              "Introduce foundational concepts, tools, and programming essentials for data science.",
            list: [
              "What is data science?",
              "The data science lifecycle.",
              "Overview of tools and technologies (Python, Jupyter Notebook).",
              "Set up a Python environment and a Jupyter Notebook.",
              "Perform basic Python operations.",
              "Brainstorm ways data science is applied in industries.",
            ],
          },
        },
        {
          name: "Data Wrangling with Python",
          details: {
            title: "Module 1",
            description:
              "Introduce foundational concepts, tools, and programming essentials for data science.",
            list: [
              "Introduction to Pandas and NumPy for data manipulation.",
              "Cleaning and preprocessing data.",
              "Load and clean a dataset (handling missing values, duplicates, etc.).",
              "Perform exploratory data analysis (EDA) using Pandas.",
              "Analyze a dataset to identify trends and patterns.",
            ],
          },
        },
        {
          name: "Data Visualization",
          details: {
            title: "Module 1",
            description:
              "Introduce foundational concepts, tools, and programming essentials for data science.",
            list: [
              "Introduction to data visualization principles.",
              "Using Matplotlib and Seaborn for creating plots and charts.",
              "Visualize data distributions, relationships, and trends.",
              "Create a dashboard to summarize dataset insights.",
              "Develop a storyboard to present findings from a dataset.",
            ],
          },
        },
        {
          name: "Statistics for Data Science",
          details: {
            title: "Module 1",
            description:
              "Introduce foundational concepts, tools, and programming essentials for data science.",
            list: [
              "Basic statistics: mean, median, mode, standard deviation.",
              "Probability, distributions, and hypothesis testing.",
              "Calculate summary statistics for datasets.",
              "Perform hypothesis tests using real-world data.",
              "Quiz on Python, data wrangling, and visualization.",
              "Mini-project: Analyze and visualize a public dataset.",
            ],
          },
        },
        {
          name: "Introduction to Machine Learning",
          details: {
            title: "Module 2",
            description:
              "Focus on predictive modeling, machine learning basics, and real-world applications.",
            list: [
              "What is machine learning?",
              "Types of machine learning (supervised, unsupervised).",
              "Build a simple linear regression model using Scikit-learn.",
              "Predict outcomes using a real-world dataset.",
            ],
          },
        },
        {
          name: "Classification Models",
          details: {
            title: "Module 2",
            description:
              "Focus on predictive modeling, machine learning basics, and real-world applications.",
            list: [
              "Introduction to classification algorithms (Logistic Regression, Decision Trees).",
              "Evaluation metrics (accuracy, precision, recall).",
              "Build and evaluate a classification model.",
              "Use confusion matrices to analyze results.",
            ],
          },
        },
        {
          name: "Unsupervised Learning",
          details: {
            title: "Module 2",
            description:
              "Focus on predictive modeling, machine learning basics, and real-world applications.",
            list: [
              "Clustering techniques (K-Means, Hierarchical Clustering).",
              "Dimensionality reduction (PCA).",
              "Cluster a dataset and interpret results.",
              "Visualize high-dimensional data with PCA.",
            ],
          },
        },
        {
          name: "Advanced Data Science Tools",
          details: {
            title: "Module 2",
            description:
              "Focus on predictive modeling, machine learning basics, and real-world applications.",
            list: [
              "Introduction to SQL for data querying.",
              "Overview of cloud platforms for data storage and analysis.",
              "Perform SQL queries on a sample database.",
              "Analyze a cloud-hosted dataset.",
              "Mid-term project: Build and present a machine learning model.",
            ],
          },
        },
        {
          name: "Advanced Topics in Data Science",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and career preparation, including a capstone project and final exam.",
            list: [
              "Time series analysis and forecasting.",
              "Introduction to deep learning concepts.",
              "Analyze a time series dataset.",
              "Build a basic neural network using TensorFlow or PyTorch.",
            ],
          },
        },
        {
          name: "Capstone Project Development",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and career preparation, including a capstone project and final exam.",
            list: [
              "Teams brainstorm and finalize project ideas.",
              "Define milestones and assign roles.",
              "Predictive sales model for a retail dataset.",
              "Sentiment analysis of customer reviews.",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and career preparation, including a capstone project and final exam.",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Transition to advanced topics and career preparation, including a capstone project and final exam.",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are Data Scientists earning?",
      subHeading:
        "Here are some average salary ranges for Data Scientists in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£48,000/year" },
        { name: "United States", value: "$113,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +9.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/3iy796",
  },
  "data-analytics": {
    curriculum: {
      topics: [
        {
          name: "Introduction to Data Analysis",
          details: {
            title: "Module 1",
            description:
              "Introduce data analysis fundamentals, tools, and techniques.",
            list: [
              "Types of data analysis (descriptive, diagnostic, predictive, prescriptive).",
              "Overview of tools: Excel, SQL, data visualization tools.",
              "Set up Excel or Google Sheets for data analysis tasks.",
              "Introduction to basic data manipulation in Excel.",
              "Discuss real-world applications of data analysis in business.",
            ],
          },
        },
        {
          name: "Data Cleaning and Preparation",
          details: {
            title: "Module 1",
            description:
              "Introduce data analysis fundamentals, tools, and techniques.",
            list: [
              "Data cleaning techniques (handling missing values, duplicates, and outliers).",
              "Data transformation (normalization, standardization).",
              "Clean and prepare a sample dataset in Excel.",
              "Perform data transformation tasks using Excel functions.",
              "Clean and prepare a real-world dataset for analysis.",
            ],
          },
        },
        {
          name: "Data Exploration and Descriptive Statistics",
          details: {
            title: "Module 1",
            description:
              "Introduce data analysis fundamentals, tools, and techniques.",
            list: [
              "Understanding descriptive statistics: mean, median, mode, variance, and standard deviation.",
              "Data visualization basics: histograms, bar charts, and pie charts.",
              "Calculate basic statistics using Excel formulas.",
              "Create visualizations to summarize data distributions.",
              "Present data insights using different types of charts.",
            ],
          },
        },
        {
          name: "Introduction to SQL for Data Analysis",
          details: {
            title: "Module 1",
            description:
              "Introduce data analysis fundamentals, tools, and techniques.",
            list: [
              "Introduction to databases and SQL queries.",
              "Basic SQL commands: SELECT, WHERE, GROUP BY, ORDER BY.",
              "Perform basic SQL queries on a sample dataset.",
              "Retrieve and filter data using SQL.",
              "Quiz on Excel data manipulation, statistics, and SQL basics.",
            ],
          },
        },
        {
          name: "Advanced Excel for Data Analysis",
          details: {
            title: "Module 2",
            description:
              "Enhance skills in data analysis through advanced techniques and tools.",
            list: [
              "Advanced Excel functions: VLOOKUP, INDEX-MATCH, and pivot tables.",
              "Introduction to data analysis add-ins (Power Query, Power Pivot).",
              "Use pivot tables to summarize data and find trends.",
              "Perform advanced lookups and data merges using Excel functions.",
            ],
          },
        },
        {
          name: "Data Visualization with Excel and Other Tools",
          details: {
            title: "Module 2",
            description:
              "Enhance skills in data analysis through advanced techniques and tools.",
            list: [
              "Principles of effective data visualization.",
              "Advanced charts and dashboards in Excel.",
              "Introduction to other visualization tools (e.g., Tableau, Google Data Studio).",
              "Create interactive dashboards in Excel.",
              "Visualize complex data sets using Tableau or Google Data Studio.",
              "Create a dashboard showcasing key business metrics using real-world data.",
            ],
          },
        },
        {
          name: "Statistical Analysis and Hypothesis Testing",
          details: {
            title: "Module 2",
            description:
              "Enhance skills in data analysis through advanced techniques and tools.",
            list: [
              "Inferential statistics: hypothesis testing, t-tests, chi-square tests.",
              "Understanding confidence intervals and p-values.",
              "Conduct hypothesis tests on datasets using Excel or Google Sheets.",
              "Interpret the results of statistical analysis and visualizations.",
            ],
          },
        },
        {
          name: "Introduction to Predictive Analytics",
          details: {
            title: "Module 2",
            description:
              "Enhance skills in data analysis through advanced techniques and tools.",
            list: [
              "What is predictive analytics?",
              "Introduction to regression analysis and predictive modeling.",
              "Build a simple linear regression model in Excel.",
              "Analyze trends and predict future outcomes using historical data.",
              "Mid-term project: Analyze a dataset and present actionable insights with visualizations and basic statistical tests.",
            ],
          },
        },
        {
          name: "Time Series Analysis",
          details: {
            title: "Module 3",
            description:
              "Work on more advanced projects and focus on career development.",
            list: [
              "Understanding time series data.",
              "Key time series analysis techniques: trend analysis, seasonal decomposition.",
              "Perform time series analysis using Excel.",
              "Visualize trends and seasonality in historical data.",
            ],
          },
        },
        {
          name: "Capstone Project Developmen",
          details: {
            title: "Module 3",
            description:
              "Work on more advanced projects and focus on career development.",
            list: [
              "Teams brainstorm and finalize project ideas.",
              "Define milestones and distribute tasks (data collection, cleaning, analysis, visualization).",
              "Sales forecasting for a retail company.",
              "Analyzing customer satisfaction survey data to identify trends.",
            ],
          },
        },
        {
          name: "Final Assessment",
          details: {
            title: "Module 3",
            description:
              "Work on more advanced projects and focus on career development.",
            list: ["Exam"],
          },
        },
        {
          name: "Presentation and Graduation",
          details: {
            title: "Module 3",
            description:
              "Work on more advanced projects and focus on career development.",
            list: [
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are Data Analysts earning?",
      subHeading:
        "Here are some average salary ranges for Data Analysts in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United States", value: "$83,000/year" },
        { name: "United Kingdom", value: "£48,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +9.00%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/b2ddi3",
  },
  "talent-acquisition": {
    curriculum: {
      topics: [
        {
          name: "Week 1: Introduction to Talent Acquisition",
          details: {
            title: "Module 1",
            description:
              "Understand the fundamentals of talent acquisition, recruitment strategies, and industry trends.",
            list: [
              "Introduction to Talent Acquisition Fundamentals.",
              "Group Discussion - Understanding the Talent Acquisition Process.",
              "Recruitment Trends Overview.",
              "Recruitment Strategies Exploration.",
            ],
          },
        },
        {
          name: "Week 2: Candidate Sourcing and Screening",
          details: {
            title: "Module 2",
            description:
              "Exploring effective candidate sourcing channels, resume screening techniques, and interview best practices.",
            list: [
              "Understanding Candidate Sourcing Channels.",
              "Prompt Engineering in Candidate Sourcing.",
              "Case Study - Effective Candidate Sourcing Techniques.",
            ],
          },
        },
        {
          name: "Week 3: Candidate Sourcing and Screening",
          details: {
            title: "Module 2",
            description:
              "Exploring effective candidate sourcing channels, resume screening techniques, and interview best practices.",
            list: [
              "Resume Screening Best Practices.",
              "Case Study - Effective Resume Screening Techniques.",
              "Leveraging Prompt Engineering to Streamline Screening Processes.",
            ],
          },
        },
        {
          name: "Week 4: Employer Branding and Candidate Experience",
          details: {
            title: "Module 3",
            description:
              "Understanding the importance of employer branding, creating a positive candidate experience, and enhancing employer-employee relationships.",
            list: [
              "Importance of Employer Branding.",
              "Building an EVP Strategy.",
              "Communicating Employer Brand Value Proposition.",
            ],
          },
        },
        {
          name: "Week 5: Employer Branding and Candidate Experience",
          details: {
            title: "Module 3",
            description:
              "Understanding the importance of employer branding, creating a positive candidate experience, and enhancing employer-employee relationships.",
            list: [
              "Enhancing Candidate Experience.",
              "Project - Designing a Candidate Experience Improvement Plan.",
              "Employer-Employee Relationship Building Techniques.",
            ],
          },
        },
        {
          name: "Week 6: Recruitment Analytics and People Technology",
          details: {
            title: "Module 4",
            description:
              "Leveraging data analytics for recruitment insights, utilising ATS/HRIS systems effectively, and integrating technology for streamlined recruitment processes.",
            list: [
              "Understanding ATS and HRIS Systems.",
              "Selecting the Right Tools for Recruitment.",
              "Optimising ATS/HRIS for Recruitment Efficiency.",
            ],
          },
        },
        {
          name: "Week 7: Recruitment Analytics and People Technology",
          details: {
            title: "Module 4",
            description:
              "Leveraging data analytics for recruitment insights, utilising ATS/HRIS systems effectively, and integrating technology for streamlined recruitment processes.",
            list: [
              "Video-based Learning - Recruitment Data Analysis.",
              "Turning Recruitment Data into a Story.",
            ],
          },
        },
        {
          name: "Week 8: Communication and Collaboration with Stakeholders",
          details: {
            title: "Module 5",
            description:
              "Developing effective communication strategies with internal and external stakeholders, including hiring managers, candidates, and recruitment agencies.",
            list: [
              "Building Strong Relationships with Hiring Managers.",
              "Managing External Stakeholder Relationships.",
              "Using Data to Influence Stakeholders and Push Back Effectively.",
              "Group Discussion - Implementing Effective Communication Techniques and Tools.",
              "Guest Speaker Session - Stakeholder Collaboration Insights.",
            ],
          },
        },
        {
          name: "Week 9: Diversity and Inclusion in Hiring",
          details: {
            title: "Module 6",
            description:
              "Understanding the importance of diversity and inclusion in recruitment, implementing D&I initiatives, and mitigating biases in hiring processes.",
            list: [
              "Understanding the Full Spectrum of Diversity, Equity, and Inclusion (DEI).",
              "DEI Nuances and Cultural Considerations - Africa.",
              "Strategies for Diversity Hiring.",
            ],
          },
        },
        {
          name: "Week 10: Diversity and Inclusion in Hiring",
          details: {
            title: "Module 6",
            description:
              "Understanding the importance of diversity and inclusion in recruitment, implementing D&I initiatives, and mitigating biases in hiring processes.",
            list: [
              "DEI Data Collection and Analysis.",
              "Communicating DEI Success Stories.",
            ],
          },
        },
        {
          name: "Week 11: Soft Skills for Talent Acquisition Professionals",
          details: {
            title: "Module 7",
            description:
              "Developing essential soft skills such as negotiation, conflict resolution, and emotional intelligence for effective talent acquisition.",
            list: [
              "Role Play - Negotiation Skills in Talent Acquisition.",
              "Case Study - Conflict Resolution Strategies.",
              "Emotional Intelligence in Recruitment.",
              "Building Empathy in Recruitment.",
              "Effective Communication Techniques.",
              "Collaboration and Leadership.",
              "Handling Stress and Pressure in Recruitment.",
            ],
          },
        },
        {
          name: "Week 12: Advanced Talent Acquisition Strategies",
          details: {
            title: "Module 8",
            description:
              "Exploring advanced strategies and techniques in talent acquisition, including niche sourcing methods, candidate engagement tactics, and innovative recruitment trends.",
            list: [
              "Niche Sourcing Methods and Targeting Passive Candidates.",
              "Candidate Engagement Tactics and Relationship Building.",
              "Innovative Recruitment Trends and Technologies.",
              "Advanced Candidate Screening Techniques.",
            ],
          },
        },
        {
          name: "Week 13: Working Remotely as a Talent Acquisition Professional",
          details: {
            title: "Module 9",
            description:
              "Adapting talent acquisition processes to remote work environments, utilizing virtual collaboration tools, and managing remote recruitment teams effectively.",
            list: [
              "Introduction to Remote Work.",
              "Effective Communication Practices in Remote Teams.",
              "Utilising Virtual Collaboration Tools for Recruitment.",
              "Managing Remote Recruitment Teams.",
              "Remote Interviewing Best Practices.",
            ],
          },
        },
        {
          name: "Week 14: Working Remotely as a Talent Acquisition Professional",
          details: {
            title: "Module 9",
            description:
              "Adapting talent acquisition processes to remote work environments, utilizing virtual collaboration tools, and managing remote recruitment teams effectively.",
            list: [
              "Crafting and presenting a complete recruitment strategy from inception.",
              "Final Exams and Presentations.",
              "Graduation ceremony with career guidance (resume review, mock interviews, etc.).",
            ],
          },
        },
      ],
    },
    earnACertificate: {
      heading: "What are talent acquisition specialists earning?",
      subHeading:
        "Some average salary ranges for Talent Acquisition Specialists in different countries:",
      countriesAndFigures: [
        { name: "Nigeria", value: "₦6M/year" },
        { name: "United Kingdom", value: "£42,000/year" },
        { name: "United States", value: "$62,000/year" },
      ],
      projectedYearGrowth: "Projected 5 year growth: +14.30%",
      fact: `Data as of June 2024; cross-referenced with Glassdoor, LinkedIn, Indeed and Payscale. Also, this data is subject to experience, location, company size, and specific skills.`,
    },
    selarLink: "https://iseschool.selar.co/43640y",
  },
};

export default courseMap;
