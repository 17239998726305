import classes from "../HomePageBuildWithEveryone/HomePageBuildWithEveryone.module.css";
import feature1 from "../../Assets/Images/feature1.svg";
import feature2 from "../../Assets/Images/feature2.svg";
import homePageBuilt1 from "../../Assets/Images/homePageBuilt1.svg";
import homePageBuilt2 from "../../Assets/Images/homePageBuilt2.svg";
import homePageBuilt3 from "../../Assets/Images/homePageBuilt3.svg";
import homePageBuilt4 from "../../Assets/Images/homePageBuilt4.svg";
import Button from "../../Components/Button/Button";
import { TCourse } from "Services";
import Skeleton from "react-loading-skeleton";

const features = [
  {
    image: feature1,
    header: "An aspiring web developer",
    description:
      "Individuals looking to build a career in  web development will benefit from a structured learning path. This course will help you build the right foundation as a developer.",
  },
  {
    image: feature2,
    header: "Graphic designers",
    description:
      "Like UI/UX designers, graphic designers looking to upskill and improve their employability will enjoy our foundational frontend development course.",
  },
  {
    image: feature1,
    header: "UI/UX designers",
    description:
      "UI/UX designers looking to expand their skills, grow in their knowledge and gain technical skills to implement designs will benefit from this course.",
  },
  {
    image: feature2,
    header: "A development hobbyist or enthusiast",
    description:
      "If you are looking to gather basic knowledge or frontend development skills because you enjoy learning, then this course will put you on the right track. ",
  },
];

interface IProps {
  course?: TCourse;
  isLoading: boolean;
  enrolLink: string;
}

const CoursePageWhoShouldEnrol = (props: IProps) => {
  const { course, isLoading, enrolLink } = props;

  const targetAudiences =
    course?.target_audiences ||
    Array(4).fill({ category: "", description: "" });

  return (
    <section className={classes.container}>
      <section className={classes.container}>
        <div className={classes.header}>
          <p>WHO SHOULD ENROL?</p>
          <h2>
            <span>
              Sign up
              <svg
                width="210"
                height="47"
                viewBox="0 0 210 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M208.175 14.147C208.271 15.2485 207.828 16.4813 206.661 17.858C205.492 19.2371 203.662 20.6769 201.182 22.143C196.226 25.0725 188.875 27.9904 179.634 30.7191C161.167 36.1718 135.357 40.8105 106.588 43.3274C77.8199 45.8443 51.5964 45.7579 32.4632 43.5948C22.8883 42.5123 15.1421 40.9153 9.75282 38.8909C7.05574 37.8777 5.00387 36.7775 3.61292 35.6224C2.22436 34.4693 1.57405 33.3321 1.47769 32.2307C1.38132 31.1292 1.82428 29.8964 2.99151 28.5197C4.16075 27.1406 5.9904 25.7008 8.47057 24.2347C13.4264 21.3051 20.7776 18.3873 30.0191 15.6586C48.486 10.2059 74.2961 5.56721 103.064 3.05031C131.833 0.533409 158.056 0.61973 177.189 2.78283C186.764 3.86533 194.511 5.46233 199.9 7.48679C202.597 8.49994 204.649 9.60018 206.04 10.7553C207.428 11.9083 208.079 13.0456 208.175 14.147Z"
                  stroke="#664EFE"
                  strokeWidth="2"
                />
              </svg>
            </span>{" "}
            if you're
          </h2>
        </div>

        <div className={classes.featureSection}>
          {targetAudiences?.map((targetAudience, i) => {
            return (
              <div className={classes.feature} key={`target_audience_${i}`}>
                <img
                  src={(i + 1) % 2 === 0 ? feature2 : feature1}
                  alt={`target_audience_image_${i}`}
                />
                <h4>{isLoading ? <Skeleton /> : targetAudience.category}</h4>
                <p>{isLoading ? <Skeleton /> : targetAudience.description}</p>
              </div>
            );
          })}
        </div>

        <p>
          Everyone can <br />
          learn
          <svg
            width="79"
            height="121"
            viewBox="0 0 79 121"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.37581 0.688948C-0.242375 1.60533 0.961731 3.71195 4.91482 8.74646C14.2701 20.4784 26.1332 31.4926 37.6962 39.0309L42.9482 42.5098L41.0597 47.2569C37.4783 56.5666 35.5695 68.314 35.6807 80.1929C35.7369 89.8943 38.3827 101.389 41.6937 106.375C41.9932 106.841 40.311 106.056 37.9642 104.693C33.7594 102.197 32.145 101.736 30.463 102.456C28.422 103.306 29.7676 104.749 37.3731 110.026C41.5795 112.959 45.8966 116.358 47.1394 117.776C48.4283 119.184 49.7992 120.525 50.2313 120.719C51.3891 121.331 53.4362 120.723 53.8845 119.699C54.1139 119.21 53.7841 116.032 53.0136 111.978C51.6079 104.037 51.5568 101.233 52.6706 94.1318C53.1485 91.3053 53.4256 88.6645 53.3317 88.2488C52.7786 85.801 48.7812 91.1217 47.7818 95.7219C47.3976 97.4591 47.0941 100.198 47.1267 101.846C47.1687 103.536 47.0453 104.923 46.8605 104.965C46.722 104.996 45.8755 103.828 45.0484 102.316C42.6747 98.0408 40.7816 88.803 40.7492 81.1404C40.6993 73.1852 41.4525 65.7765 42.9243 59.376C44.1366 53.9996 47.1457 45.1644 47.8385 45.0078C48.0232 44.9661 49.7143 45.3607 51.6215 45.8521C53.4825 46.3539 55.9856 46.9049 57.1365 47.0332C67.2687 48.2389 75.3097 43.4123 77.6918 34.8104C78.3962 32.127 78.4482 30.8531 77.9848 27.9427C77.2556 23.6414 76.1739 21.6475 73.4606 19.7363C69.6505 17.0535 63.957 17.6603 58.6647 21.3319C55.1861 23.7684 52.0348 27.2474 48.23 32.9668L44.8584 38.049L41.1362 35.5406C32.3521 29.5588 21.3042 19.574 11.289 8.57373C4.2552 0.88578 3.02887 -0.244926 1.6433 0.0681465C1.08907 0.193376 0.473784 0.478034 0.37697 0.694084L0.37581 0.688948ZM70.4258 23.0865C73.9332 24.4299 75.5932 28.7689 74.326 33.4727C72.1843 41.8261 63.5367 45.0422 52.866 41.477L49.9247 40.4911L50.7707 38.6495C52.0293 35.8408 57.3245 28.9594 59.8794 26.7316C63.8323 23.3627 67.6076 22.0242 70.4207 23.0876L70.4258 23.0865Z"
              fill="black"
            />
          </svg>
        </p>

        <div className={classes.quizSection}>
          <p>
            Whether you are just starting out in tech, switching careers or
            looking to try a new skill, there is a course for you.
          </p>

          <div className={classes.buttonSection}>
            <Button type="white">
              <a href={enrolLink} target="_blank" rel="noopener noreferrer">
                Start learning
              </a>
            </Button>
          </div>
        </div>

        <img src={homePageBuilt1} alt="Built with everyone in mind" />
        <img src={homePageBuilt2} alt="Built with everyone in mind" />
        <img src={homePageBuilt4} alt="Built with everyone in mind" />
        <img src={homePageBuilt3} alt="Built with everyone in mind" />
      </section>
    </section>
  );
};

export default CoursePageWhoShouldEnrol;
