import React, { useRef } from "react";
import Layout from "../../Components/Layout/Layout";
import HomePageAffordable from "../HomePageAffordable/HomePageAffordable";
import SchoolPageCoursesSection from "../SchoolPageCoursesSection/SchoolPageCoursesSection";
import SchoolPageDidYouKnow from "../SchoolPageDidYouKnow/SchoolPageDidYouKnow";
import SchoolPageHeroSection from "../SchoolPageHeroSection/SchoolPageHeroSection";
// import SchoolPageTutors from "../SchoolPageTutors/SchoolPageTutors";
import SchoolPageWhatWeOffer from "../SchoolPageWhatWeOffer/SchoolPageWhatWeOffer";
import useSWR from "swr";
import { endpoints, getSchoolBySlug } from "Services";
import { useParams } from "react-router-dom";
import { scrollToElementWithGap } from "v2/HelperFunctions/scrollToTop";

const SchoolPage = () => {
  const { schoolSlug } = useParams();
  const coursesSectionRef = useRef<any>();

  const { data: response, isLoading } = useSWR(
    endpoints.GET_SCHOOL_BY_SLUG(schoolSlug || ""),
    getSchoolBySlug
  );

  const school = response?.data;

  const handleExploreCourses = () => {
    scrollToElementWithGap(coursesSectionRef);
  };

  return (
    <Layout>
      <SchoolPageHeroSection
        isLoading={isLoading}
        name={school?.name || ""}
        description={school?.description || ""}
        tagline={school?.tagline || ""}
        onExploreCourses={handleExploreCourses}
      />

      <div ref={coursesSectionRef}>
        <SchoolPageCoursesSection />
      </div>

      <SchoolPageDidYouKnow
        isLoading={isLoading}
        facts={school?.facts || ""}
        factsCoverImage={school?.facts_cover_image || ""}
        salaryRange={school?.salary_range || ""}
        salaryRangeText={school?.salary_range_text || ""}
        careerPathNo={school?.career_path_no || ""}
      />

      <SchoolPageWhatWeOffer />

      {/* <SchoolPageTutors /> */}

      <HomePageAffordable />
    </Layout>
  );
};

export default SchoolPage;
