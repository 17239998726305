import { headerNavItemsType } from "./types";

export const headerNavItems: headerNavItemsType = [
  {
    title: "Home",
    route: "/",
    keywords: [""],
    otherOptions: null,
    isVisible: true,
  },
  {
    title: "Schools",
    route: null,
    keywords: ["1", "2"],
    otherOptions: {
      isActive: false,
      data: [],
    },
    isVisible: true,
  },
  {
    title: "Why iṣẹ́ School",
    route: "/why-ise-school",
    keywords: ["why-ise-school"],
    otherOptions: null,
    isVisible: true,
  },
  {
    title: "Resources",
    route: null,
    keywords: ["blog", "faqs"],
    otherOptions: {
      isActive: false,
      data: [
        {
          title: "Blog",
          route: "/blog",
          isLive: false,
        },

        {
          title: "FAQs",
          route: "/faqs",
          isLive: true,
        },
      ],
    },

    isVisible: true,
  },
  {
    title: "Collaborate",
    route: null,
    keywords: [""],
    otherOptions: {
      isActive: false,
      data: [
        {
          title: "Become A Tutor",
          route: "/become-a-tutor",
          isLive: true,
        },

        {
          title: "Sponsor A Student",
          route: "/sponsor-a-student",
          isLive: true,
        },
      ],
    },

    isVisible: true,
  },
  // {
  //   title: "Collaborate",
  //   route: "/sponsor-a-student",
  //   keywords: ["collaborate", "sponsor-a-student"],
  //   otherOptions: null,
  //   isVisible: true,
  // },
];
