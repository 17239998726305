import classes from "./CoursePageWhatYouNeed.module.css";
import whatYouNeed from "../../Assets/Images/whatYouNeed.svg";
import admission from "../../Assets/Images/admission.svg";
import Button from "../../Components/Button/Button";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";

const requirements = [
  "The program is open to anyone with no prior experience.",
  "The motivation, grit and commitment to transform your career.",
  "Have a basic understanding of English to engage with others.",
  "Basic tech literacy is necessary to utilize tools like Google Suite.",
  "Mandatory availability for live classes at least twice a week.",
  "A computer (macOS, Windows, or Linux) and/or smartphone with internet connection.",
  "Ability to dedicate at least 1 hour daily to the learning process.",
];

const admissionSteps = [
  {
    title: "Sign up on the platform",
    caption:
      "To begin your admission process, sign up and create an account on iṣẹ School. ",
  },
  {
    title: "Take the eligibility quiz",
    caption:
      "Once you’ve signed up, and decided to take the course, you’ll take a 5-minutes quiz to determine if you’re a suitable student for iṣẹ́ School.",
  },
  {
    title: "Pay for the course",
    caption:
      "Once you pass the eligibility stage, go to the course page, click on the “Enrol” button. Follow the prompt and pay for the course with our payment gateway.",
  },
  {
    title: "Start learning",
    caption:
      "As soon as your payment is confirmed, you’ll be sent an onboarding email leading to your dashboard and you can start learning",
  },
];

interface IProps {
  enrolLink: string;
}

const CoursePageWhatYouNeed = ({ enrolLink }: IProps) => {
  // Context
  const { courseAdmission, courseRequirement } = useContext(AppContext);

  return (
    <section className={classes.container}>
      <div className={classes.upperSection} ref={courseRequirement}>
        <div className={classes.header}>
          <p>WHAT YOU NEED</p>
          <h2>
            Learning{" "}
            <span>
              requirements
              <svg
                width="279"
                height="51"
                viewBox="0 0 279 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M277.469 12.9915C277.561 14.0447 276.99 15.3231 275.353 16.8299C273.733 18.3199 271.227 19.8833 267.867 21.4825C261.156 24.6756 251.268 27.9064 238.898 30.9817C214.175 37.1286 179.72 42.6116 141.401 45.9641C103.082 49.3166 68.1985 49.8999 42.7833 48.1396C30.0677 47.2589 19.7681 45.7944 12.6054 43.8149C9.01825 42.8236 6.27858 41.7192 4.42522 40.5329C2.55106 39.3334 1.76644 38.1736 1.6743 37.1204C1.58215 36.0672 2.15347 34.7888 3.79086 33.2821C5.41007 31.792 7.91633 30.2286 11.2768 28.6295C17.987 25.4363 27.8758 22.2055 40.2453 19.1302C64.9687 12.9833 99.4235 7.50028 137.743 4.1478C176.062 0.795317 210.945 0.212059 236.36 1.97236C249.076 2.85306 259.375 4.31753 266.538 6.29698C270.125 7.2883 272.865 8.39275 274.718 9.57898C276.592 10.7785 277.377 11.9383 277.469 12.9915Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </span>
          </h2>
        </div>

        <div className={classes.requirementsListSection} ref={courseAdmission}>
          <img src={whatYouNeed} alt="What you need" />
          <ul>
            {requirements.map((data) => {
              return (
                <li key={data}>
                  <span>
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 13.1118L9 17.1118L19 7.11182"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span>{data}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className={classes.bottomSection}>
        <div className={classes.header}>
          <p>GET ONBOARD IN 4 SIMPLE STEPS</p>
          <h2>
            Admission{" "}
            <span>
              process
              <svg
                width="186"
                height="36"
                viewBox="0 0 186 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M184.848 12.0671C184.893 12.798 184.533 13.6843 183.467 14.7337C182.407 15.7776 180.757 16.8695 178.52 17.9781C174.052 20.1912 167.464 22.3641 159.206 24.3642C142.706 28.3606 119.731 31.6219 94.2019 33.1889C68.6725 34.7558 45.4719 34.3287 28.6065 32.3803C20.1656 31.4051 13.3611 30.0543 8.65696 28.4041C6.30064 27.5775 4.52961 26.6956 3.3497 25.7891C2.16374 24.878 1.69817 24.0423 1.65331 23.3114C1.60845 22.5806 1.96833 21.6942 3.03396 20.6449C4.09415 19.6009 5.74403 18.509 7.98157 17.4005C12.4486 15.1874 19.0368 13.0145 27.2951 11.0143C43.7956 7.01796 66.7698 3.75668 92.2992 2.18971C117.829 0.622749 141.029 1.04986 157.895 2.99827C166.335 3.97343 173.14 5.3243 177.844 6.97448C180.2 7.80106 181.971 8.68301 183.151 9.58948C184.337 10.5006 184.803 11.3363 184.848 12.0671Z"
                  stroke="#1B695C"
                  stroke-width="2"
                />
              </svg>
            </span>
          </h2>
        </div>

        <div className={classes.admissionSection}>
          <div>
            {admissionSteps?.map((data, i) => {
              return (
                <div>
                  <div>{i + 1}</div>
                  <div>
                    <p>{data?.title}</p>
                    <p>{data?.caption}</p>
                  </div>
                  <div></div>
                </div>
              );
            })}
          </div>

          <img src={admission} alt="Admission processes" />
        </div>

        <Button type="tertiary">
          <a href={enrolLink} target="_blank" rel="noopener noreferrer">
            Enrol now
          </a>
        </Button>
      </div>
    </section>
  );
};

export default CoursePageWhatYouNeed;
