import Skeleton from "react-loading-skeleton";

import classes from "./SchoolPageDidYouKnow.module.css";
import schoolPageDidYouKnow from "../../Assets/Images/schoolPageDidYouKnow.svg";

interface IProps {
  isLoading: boolean;
  facts: string;
  factsCoverImage: string;
  salaryRange: string;
  salaryRangeText: string;
  careerPathNo: string;
}

const SchoolPageDidYouKnow = (props: IProps) => {
  const {
    isLoading,
    facts,
    salaryRange,
    salaryRangeText,
    careerPathNo,
    factsCoverImage,
  } = props;

  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <h6>DID YOU KNOW?</h6>

        <h2>{isLoading ? <Skeleton /> : facts}</h2>

        <div className={classes.estimates}>
          <div>
            <h3>{isLoading ? <Skeleton /> : salaryRange}</h3>
            <p>{isLoading ? <Skeleton /> : salaryRangeText}</p>
          </div>

          <div>
            <h3>{isLoading ? <Skeleton /> : careerPathNo}</h3>
            <p>Possible career paths</p>
          </div>
        </div>

        <p>
          Tech-savvy leaders are in high demand! A McKinsey & Company report
          revealed that 87% of CEOs believe digital skills are crucial for
          business success.
        </p>
      </div>

      <div className={classes.imagesSection}>
        <img
          src={factsCoverImage}
          alt="fact_cover_image"
          className="object-cover"
        />
      </div>
    </section>
  );
};

export default SchoolPageDidYouKnow;
