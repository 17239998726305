import { useContext, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import DropdownWithSearch from "Components/DropdownWithSearch/DropdownWithSearch";

interface IProps {
  curriculum: {
    topics: {
      name: string;
      details: {
        title: string;
        description: string;
        list: string[];
      };
    }[];
  };
}

const CoursePageCurricullum = (props: IProps) => {
  const { curriculum } = props;
  // Context
  const { courseCurricullum } = useContext(AppContext);

  const [selectedTopicIndex, setSelectedTopicIndex] = useState("0");

  const selectedTopic = curriculum.topics[Number(selectedTopicIndex)];

  return (
    <section ref={courseCurricullum}>
      <div className="bg-[#F7F5FF] pt-16 pb-8 px-5 lg:px-20">
        <h4 className="text-[#664EFE] text-[13px] font-medium tracking-[5%] mb-2">
          WELL CURATED CURRICULUM
        </h4>

        <h2 className="text-[31px] md:text-[49px] max-w-[1071px] font-medium md:leading-[60px]">
          Structured lessons, videos, and audios for optimal learning
        </h2>
      </div>

      <div className="flex flex-col lg:flex-row px-5 lg:px-20 pt-6">
        <section className="md:min-w-[560px] md:pr-16">
          <h3 className="text-lg text-[#2E2E2E] font-medium mb-5">
            EXPLORE THE TOPICS
          </h3>

          <ul className="hidden pl-3.5 lg:flex flex-col gap-y-5 overflow-y-scroll h-[400px] curriculum-topics">
            {curriculum.topics.map((topic, i) => (
              <ol
                className={
                  i.toString() !== selectedTopicIndex
                    ? "text-[#B9B9B9]"
                    : "text-[#2E2E2E]"
                }
              >
                <button onClick={() => setSelectedTopicIndex(i.toString())}>
                  {i + 1}. {topic.name}
                </button>
              </ol>
            ))}
          </ul>

          <div className="lg:hidden">
            <DropdownWithSearch
              title="Select State"
              options={curriculum.topics.map((topic) => topic.name)}
              selected={curriculum.topics[Number(selectedTopicIndex)].name}
              setSelected={(value) => {
                const topicIndex = curriculum.topics.findIndex(
                  (topic) => topic.name === value
                );

                if (topicIndex) {
                  setSelectedTopicIndex(topicIndex.toString());
                }
              }}
            />
          </div>
        </section>

        <section className="max-w-[496px] lg:px-2">
          <h2 className="text-[#2E2E2E] text-[25px] mb-2.5 font-medium">
            {selectedTopic.details.title}
          </h2>

          <p className="text-[#2E2E2E] mb-4">
            {selectedTopic.details.description}
          </p>

          <div className="flex flex-col gap-y-2.5">
            {selectedTopic.details.list.map((item, i) => (
              <div className="flex items-center gap-x-3" key={`topic_${i}`}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  className="shrink-0"
                >
                  <circle cx="16" cy="16" r="15.5" stroke="#DCD7FE" />
                  <path
                    d="M9 17L13 21L23 11"
                    stroke="#664EFE"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span>{item}</span>
              </div>
            ))}
          </div>
        </section>
      </div>
    </section>
  );
};

export default CoursePageCurricullum;
