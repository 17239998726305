import useSWR from "swr";
import Footer from "../../Containers/Footer/Footer";
import Header from "../../Containers/Header/Header";
import classes from "./Layout.module.css";
import { endpoints, getActiveSchools } from "Services";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  // Requests
  // Requests
  const { data } = useSWR(endpoints.GET_ACTIVE_SCHOOLS, getActiveSchools);

  return (
    <section className={classes.container}>
      <nav className={classes.header}>
        <Header data={data} />
      </nav>

      <div className={classes.body}>{children}</div>

      <footer>
        <Footer />
      </footer>
    </section>
  );
};

export default Layout;
