import moment from "moment";
import { useState } from "react";
import Button from "../../Components/Button/Button";
import SectionsHeader from "../../Components/SectionsHeader/SectionsHeader";
import classes from "./CourseDetailWhenWeStart.module.css";
import useInterval from "v2/Hooks/useInterval";
import getTimeUntilDeadline from "Utilities/getTimeUntilDeadline";

interface IProps {
  startDate?: string;
  endDate?: string;
  deadline?: string;
  enrolLink: string;
}

const formatDate = (dateString: Date | string) => {
  if (!dateString) {
    return "not known at this time";
  }
  const date = moment(dateString);

  const formattedDate = date.format("Do MMMM, YYYY");

  return formattedDate;
};

const CountDownCard = (props: { label: string; value: number }) => {
  const { label, value } = props;

  return (
    <div>
      <div>
        <span>{value}</span>
      </div>
      <div>
        <span>{label}</span>
      </div>
    </div>
  );
};

const CohortCountDownDetailsCard = (props: { data: any; type: string }) => {
  return (
    <div className="h-[108px] md:h-[111px] bg-[#EEEBFE] md:w-1/2 rounded-lg flex flex-col items-center justify-center">
      <p className="text-[#2E2E2E] text-lg md:text-[25px] font-medium mb-2.5">
        {props.type === "start" ? "Course Starts" : "Application deadline"}
      </p>
      {/* The next cohort starts */}
      <p className="text-sm md:text-base">
        <span className="text-[#737373]">
          {props.type === "start"
            ? "The next cohort starts"
            : "Deadline for application is"}
        </span>{" "}
        <span className="font-medium">
          {props.type === "start"
            ? formatDate(props.data)
            : formatDate(props.data)}
        </span>
      </p>
    </div>
  );
};

const CourseDetailWhenWeStart = ({
  enrolLink,
  startDate,
  deadline,
}: IProps) => {
  // Context
  const [timeRemaining, setTimeRemaining] = useState(() =>
    getTimeUntilDeadline((deadline as string) || "")
  );

  useInterval(() => {
    setTimeRemaining(getTimeUntilDeadline(deadline || null));
  }, 1000);

  return (
    <section className={classes.container}>
      <div className={classes.header}>
        <SectionsHeader
          header="Our cohorts are filling up already!"
          paragraph="NEXT COHORT STARTS"
          style={{ textAlign: "center" }}
        />
      </div>

      <div className="mt-10 mb-10 max-w-[1074px] mx-auto">
        <div className={`${classes.daysCounter}`}>
          <CountDownCard label="Days" value={timeRemaining.days} />

          <CountDownCard label="Hours" value={timeRemaining.hours} />

          <CountDownCard label="Minutes" value={timeRemaining.minutes} />

          <CountDownCard label="Seconds" value={timeRemaining.seconds} />
        </div>

        <div className="flex flex-col md:flex-row border gap-x-5 gap-y-5 border-[#664EFE] rounded-[10px] p-3 md:p-5 mt-12">
          <CohortCountDownDetailsCard type="start" data={startDate} />
          <CohortCountDownDetailsCard type="end" data={deadline} />
        </div>
      </div>

      <div className={classes.buttonSection}>
        <Button>
          <a href={enrolLink} target="_blank" rel="noopener noreferrer">
            Enrol now
          </a>
        </Button>
      </div>
    </section>
  );
};

export default CourseDetailWhenWeStart;
