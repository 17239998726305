import Button from "../../Components/Button/Button";
import classes from "./SchoolPageWhatWeOffer.module.css";
import whatWeOffer1 from "../../Assets/Images/whatWeOffer1.svg";
import whatWeOffer2 from "../../Assets/Images/whatWeOffer2.svg";
import whatWeOffer3 from "../../Assets/Images/whatWeOffer3.svg";
import whatWeOffer4 from "../../Assets/Images/whatWeOffer4.svg";
import getAppLink from "v2/HelperFunctions/getAppLink";

const whatWeOffer = [
  {
    image: whatWeOffer1,
    header: "Interactive lessons",
    captions: [
      "Audio and video lessons ",
      "1-on-1 tutor and student calls",
      "Regular assessments and tests",
    ],
  },
  {
    image: whatWeOffer2,
    header: "Theoretical and practical learning",
    captions: [
      "Expert webinars and seminars",
      "Intensive course curriculum",
      "Community and peer to peer learning",
    ],
  },
  {
    image: whatWeOffer1,
    header: "Capstone project",
    captions: [
      "Build portfolio projects",
      "Showcase practical skills",
      "Demonstrate expertise",
    ],
  },
  {
    image: whatWeOffer4,
    header: "Career guidance",
    captions: [
      "Audio and video lessons",
      "1-on-1 tutor and student calls",
      "Regular assessments and tests",
    ],
  },
];

const SchoolPageWhatWeOffer = () => {
  return (
    <section className={classes.container}>
      <div className={classes.textSection}>
        <h6 className="text-[#1B695C]">WHAT WE OFFER</h6>
        <h2>Beginner-friendly learning experiences</h2>
        <p>
          Our learning path and curriculum helps you build in-demand skills even
          as a complete newbie.
        </p>
        <Button>
          <a href={getAppLink()} target="_blank" rel="noopener noreferrer">
            Start learning
          </a>
        </Button>

        <div className={classes.decoration}>
          <svg
            width="126"
            height="133"
            viewBox="0 0 126 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 0 116.305)"
              fill="#664EFE"
            />
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 25.8984 79.3691)"
              fill="#FFD029"
            />
          </svg>

          <svg
            width="126"
            height="133"
            viewBox="0 0 126 133"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 0 116.305)"
              fill="#664EFE"
            />
            <ellipse
              cx="41.8244"
              cy="40"
              rx="41.8244"
              ry="40"
              transform="matrix(0.315762 -0.948838 0.93689 0.349625 25.8984 79.3691)"
              fill="#FFD029"
            />
          </svg>
        </div>
      </div>
      <div className={classes.cardsSection}>
        {whatWeOffer.map((data, i) => {
          return (
            <div key={i}>
              <img src={data?.image} alt={data?.header} />
              <h4>{data?.header}</h4>
              {data?.captions?.map((data) => {
                return <p key={data}>{data}</p>;
              })}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SchoolPageWhatWeOffer;
