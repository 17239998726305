import Skeleton from "react-loading-skeleton";

import Button from "../../Components/Button/Button";
import classes from "./SchoolPageHeroSection.module.css";
import schoolPageHero from "../../Assets/Images/schoolPageHero.svg";
import avantLogo from "../../Assets/Images/avantLogo.svg";
import ishaLogo from "../../Assets/Images/ishaLogo.svg";
import chargeBee from "../../Assets/Images/chargeBee.svg";
import clane from "../../Assets/Images/clane.svg";
import paga from "../../Assets/Images/paga.svg";
import robinHood from "../../Assets/Images/robinHood.svg";
import sofiLogo from "../../Assets/Images/sofiLogo.svg";
import stripe from "../../Assets/Images/stripe.svg";
import Svgs from "Assets/Svgs";
import businessManagementImage from "Assets/Images/school-illustrations/business.png";
import productImage from "Assets/Images/school-illustrations/product.png";
import dataImage from "Assets/Images/school-illustrations/data.png";
import developmentImage from "Assets/Images/school-illustrations/development.png";
import { useParams } from "react-router-dom";

const brands = [
  avantLogo,
  sofiLogo,
  ishaLogo,
  stripe,
  clane,
  robinHood,
  chargeBee,
  paga,
];

const SchoolPageHeroSection = (props: {
  name: string;
  description: string;
  isLoading: boolean;
  tagline: string;
  onExploreCourses: () => void;
}) => {
  const { name, description, isLoading, onExploreCourses, tagline } = props;
  const { schoolSlug } = useParams();

  const schoolImage = {
    "ise-school-of-business-and-management": businessManagementImage,
    "ise-school-of-product": productImage,
    "ise-school-of-data-and-ai": dataImage,
    "ise-school-of-data-and-artificial-intelligence": dataImage,
    "ise-school-of-software-and-development": developmentImage,
  }[schoolSlug as string];

  return (
    <section className={classes.outerContainer}>
      <div
        className={`${classes.container} flex flex-col md:flex-row md:justify-between`}
      >
        <div>
          <div className="flex items-center gap-x-3 mb-2">
            <Svgs.SchoolIcon />

            <span className="text-[13px] text-[#1B695C]">{tagline}</span>
          </div>

          <h1 className="text-3xl md:text-[61px] max-w-[674px] font-medium leading-[45px] md:leading-[75px] mb-3">
            {isLoading ? <Skeleton /> : name}
          </h1>

          <p className="text-[#737373] text-sm md:text-base max-w-[656px] mb-9">
            {isLoading ? <Skeleton count={3} /> : description}
          </p>

          <div className="w-[193px]">
            <Button onClick={onExploreCourses}>Explore courses</Button>
          </div>
        </div>

        <div className="max-h-[480px] flex">
          <img
            src={schoolImage}
            alt="Welcome to Ise school"
            className="w-full max-h-[480px]"
          />
        </div>
      </div>

      <div className={classes.brandSection}>
        <h4>Where some of our tutors work</h4>
        <div className={classes.logoSection}>
          {brands.map((data) => (
            <img key={data} src={data} alt="Our tutor brands" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SchoolPageHeroSection;
