import { useContext } from "react";
import Button from "../../Components/Button/Button";
import Loader from "../../Components/Loader/Loader";
import SchoolCard from "../../Components/SchoolCard/SchoolCard";
import { AppContext } from "../../Context/AppContext";
import { routes } from "../../Utilities/routes";
import classes from "./HomePageSchools.module.css";
import Svgs from "../../../Assets/Svgs";
import { Link } from "react-router-dom";
import { TSchool } from "Services";

interface IProps {
  schools: TSchool[];
  isLoading: boolean;
}

const HomePageSchools = ({ schools, isLoading }: IProps) => {
  // Context
  const { coursesRef } = useContext(AppContext);

  return (
    <section className={classes.container} ref={coursesRef}>
      <div>
        <p className="text-center text-[13px] font-medium text-[#3D20FE]">
          WHAT WOULD YOU LIKE TO LEARN?
        </p>

        <div className="flex items-center justify-center">
          <h2 className="text-center font-medium text-[31px] md:text-[49px]">
            Schools{" "}
            <span className="relative">
              <Svgs.Eclipse658 className="absolute top-0 left-0 text-[#664EFE] w-[151.79px] h-[33.58px] md:w-[233px] md:h-[51px]" />
              made to fit
            </span>{" "}
            your interests
          </h2>

          <span className="hidden md:flex">
            <Svgs.Star />
          </span>
        </div>

        <p className="text-center text-[#454545]">
          Whatever tech path you choose our resources and courses are available
          to help you learn and build your career
        </p>
      </div>

      <div className={classes.schoolsSection}>
        {isLoading ? (
          <Loader />
        ) : (
          schools?.map((school, i) => {
            return <SchoolCard key={`school_${i}`} data={school} />;
          })
        )}
      </div>

      <div className="flex justify-center">
        <Svgs.UnsureArrow />
      </div>

      <div className="bg-[#664EFE] max-w-[535px] mx-auto mt-10 rounded-[20px] py-7 px-8">
        <p className="text-center text-white mb-6">
          We’ve curated a career quiz to help you determine your strengths. If
          you prefer one-on-one support, speak to an advisor for free
        </p>

        <div className={classes.buttonSection}>
          <Button
            type="white"
            onClick={() => {
              window.open(
                `${window.location.origin}${routes.FIT_QUIZ}?step=intro`
              );
            }}
          >
            Take our quiz
          </Button>
          <Button type="transparent-white">
            <Link to="/program-advisor">Speak to advisor</Link>
          </Button>
        </div>
      </div>

      <svg
        width="265"
        height="237"
        viewBox="0 0 265 237"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="32.9694"
          cy="54.9084"
          r="80.3802"
          transform="rotate(-26.6517 32.9694 54.9084)"
          fill="#2BA792"
        />
        <circle
          cx="119.868"
          cy="40.4075"
          r="80.3802"
          transform="rotate(-26.6517 119.868 40.4075)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="140"
        height="226"
        viewBox="0 0 140 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10.2291"
          y="139.064"
          width="195.798"
          height="54.0133"
          transform="rotate(-34.6796 10.2291 139.064)"
          fill="#2BA792"
        />
        <rect
          x="39.7493"
          y="182.239"
          width="195.798"
          height="20.255"
          transform="rotate(-35.8642 39.7493 182.239)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="220"
        height="349"
        viewBox="0 0 220 349"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="201.17"
          height="127.191"
          transform="matrix(-0.681331 0.731975 -0.731629 -0.681703 122.452 183.312)"
          fill="#FFD029"
        />
        <rect
          width="167.107"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 47.6935 113.478)"
          fill="#2BA792"
        />
        <rect
          width="142.443"
          height="12.3054"
          transform="matrix(-0.690768 0.723077 -0.722731 -0.691129 146.092 10.5166)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="161"
        height="226"
        viewBox="0 0 161 226"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-48.7742"
          y="139.064"
          width="195.798"
          height="54.0133"
          transform="rotate(-34.6796 -48.7742 139.064)"
          fill="#2BA792"
        />
        <rect
          x="-19.254"
          y="182.239"
          width="195.798"
          height="20.255"
          transform="rotate(-35.8642 -19.254 182.239)"
          fill="#FFD029"
        />
      </svg>

      <svg
        width="220"
        height="254"
        viewBox="0 0 220 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="144.969"
          cy="195.908"
          r="80.3802"
          transform="rotate(-26.6517 144.969 195.908)"
          fill="#2BA792"
        />
        <circle
          cx="231.868"
          cy="181.407"
          r="80.3802"
          transform="rotate(-26.6517 231.868 181.407)"
          fill="#FFD029"
        />
      </svg>
    </section>
  );
};

export default HomePageSchools;
