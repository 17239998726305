import classes from "./CourseCard.module.css";
import iseLogo from "../../Assets/Images/iseLogo.svg";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../Components/Button/Button";
import { scrollToTheTop } from "../../HelperFunctions/scrollToTop";
import { TSchoolCourse } from "Services";

interface IProps {
  data: TSchoolCourse;
}

const CourseCard = ({ data }: IProps) => {
  // Router
  const navigate = useNavigate();
  const { schoolSlug } = useParams();

  return (
    <div className={classes.container}>
      <img src={data?.courses_cover_image || iseLogo} alt="School" />
      <h4>{data?.courses_name}</h4>

      <p>{data?.courses_overview}</p>
      <div className={classes.infoSection}>
        <span>3- months</span>
        <span>Beginners friendly</span>
      </div>
      <Button
        onClick={() => {
          scrollToTheTop();
          navigate(`/${schoolSlug}/${data?.courses_slug}`);
        }}
      >
        Explore course
      </Button>
    </div>
  );
};

export default CourseCard;
