import Layout from "v2/Components/Layout/Layout";

import IMAGE_PROGRAM_ADVISOR_HERO from "Assets/Images/program-advisor-hero.jpg";
import IMAGE_WHAT_WE_NEED from "Assets/Images/what-we-need.jpg";
import IMAGE_TUTOR_HERO from "Assets/Images/tutor-hero.jpg";
import Button from "v2/Components/Button/Button";
import HomePageAffordable from "v2/Containers/HomePageAffordable/HomePageAffordable";

import avantLogo from "v2/Assets/Images/avantLogo.svg";
import ishaLogo from "v2/Assets/Images/ishaLogo.svg";
import chargeBee from "v2/Assets/Images/chargeBee.svg";
import clane from "v2/Assets/Images/clane.svg";
import paga from "v2/Assets/Images/paga.svg";
import robinHood from "v2/Assets/Images/robinHood.svg";
import sofiLogo from "v2/Assets/Images/sofiLogo.svg";
import stripe from "v2/Assets/Images/stripe.svg";

const brands = [
  avantLogo,
  sofiLogo,
  ishaLogo,
  stripe,
  clane,
  robinHood,
  chargeBee,
  paga,
];

const Hero = () => {
  return (
    <section className="pt-[150px] pb-14 md:pb-0 px-5 md:px-[80px] bg-[#FFFAEB] min-h-[650px] flex justify-between">
      <div className="max-w-[628px]">
        <div className="bg-[#EEEBFE] w-[226px] h-[45px] flex items-center justify-center rounded-[10px] gap-x-2 mb-4">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.01314 4.14452C2.89068 4.25067 2.7926 4.38203 2.72561 4.52959C2.65862 4.67716 2.6243 4.83746 2.62501 4.99952V20.7495C2.62501 21.0479 2.74354 21.334 2.95452 21.545C3.16549 21.756 3.45164 21.8745 3.75001 21.8745C4.04838 21.8745 4.33453 21.756 4.54551 21.545C4.75648 21.334 4.87501 21.0479 4.87501 20.7495V16.7858C7.01626 15.1808 8.82189 15.9308 11.5013 17.2545C13.0228 18.0045 14.7581 18.867 16.6388 18.867C18.0188 18.867 19.4775 18.4039 20.9869 17.097C21.1084 16.9917 21.2059 16.8616 21.2729 16.7154C21.3398 16.5692 21.3746 16.4103 21.375 16.2495V4.99952C21.3751 4.78382 21.3131 4.57265 21.1965 4.39117C21.0799 4.2097 20.9136 4.06557 20.7174 3.97596C20.5212 3.88635 20.3033 3.85504 20.0898 3.88576C19.8763 3.91647 19.6761 4.00792 19.5131 4.14921C17.1966 6.1564 15.3384 5.39702 12.4988 3.99171C9.86157 2.68109 6.57845 1.0564 3.01314 4.14452ZM19.125 15.7114C16.9838 17.3173 15.1781 16.5655 12.4988 15.2426C10.3275 14.1645 7.71845 12.8745 4.87501 14.1748V5.53296C7.01626 3.92796 8.82189 4.67796 11.5013 6.00171C13.0228 6.75171 14.7581 7.61421 16.6388 7.61421C17.4972 7.6155 18.3457 7.42962 19.125 7.06952V15.7114Z"
              fill="#2E2E2E"
            />
          </svg>

          <span className="font-semibold text-lg">NEED GUIDANCE?</span>
        </div>

        <h1 className="text-[36px] md:text-[61px] leading-[45px] md:leading-[75px] font-medium">
          Talk to our program advisor
        </h1>

        <div className="md:hidden mt-5">
          <img
            src={IMAGE_PROGRAM_ADVISOR_HERO}
            className="min-w-[343px] object-cover rounded-[10px]"
            alt="sponsor-hero"
          />
        </div>

        <p className="text-[#2E2E2E] mt-4">
          Discover and explore learning paths that match your career goals and
          aspirations with the help of our program advisors.
        </p>

        <div className="w-[208px] mt-14">
          <Button>
            <a href="https://calendar.app.google/UxPkWgnsb8kApR8B8">
              Schedule free call
            </a>
          </Button>
        </div>

        <p className="text-[#737373] mt-2">
          We keep your information confidential and won't contact you without
          permission.
        </p>

        <p className="text-lg font-medium mb-3 mt-8">
          Where some of our tutors work
        </p>

        <div className="flex items-center justify-center gap-x-5">
          {brands.map((brand, i) => (
            <div>
              <img src={brand} key={i} alt={brand + i.toString()} />
            </div>
          ))}
        </div>
      </div>

      <div className="hidden md:block">
        <img
          src={IMAGE_PROGRAM_ADVISOR_HERO}
          className="max-w-[678px] object-cover rounded-[10px]"
          alt="sponsor-hero"
        />
      </div>
    </section>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      title: "Explore our courses",
      description:
        "Go through our list of available courses. When you find a course  that suits your intended career path, express your interest to speak with an advisor.",
    },
    {
      title: "Schedule a consultation",
      description:
        "Use the “Schedule free call” button  to set a date to discuss your goals and expectations with an advisor.",
    },
    {
      title: "Get personalized recommendations",
      description:
        "Our program advisor will provide personalized course recommendations based on your career goals and learning preferences.",
    },
  ];
  return (
    <section className="px-5 md:px-[80px] flex flex-col md:flex-row pt-20 pb-24">
      <div className="md:w-1/2">
        <p className="text-[#1B695C] text-[10px] md:text-[13px] font-medium tracking-[5%]">
          IN 3 EASY STEPS
        </p>

        <h2 className="text-[31px] md:text-[49px] font-medium">How to works</h2>
      </div>

      <div className="flex flex-col gap-y-10 relative mt-8 md:mt-0">
        <div className="h-[80%] w-[1.5px] bg-[#1B695C]  absolute left-[23px] -z-10"></div>
        {steps.map((step, i) => (
          <div key={i}>
            <div className="flex">
              <div className="h-12 w-12 rounded-full bg-[#1B695C] flex items-center justify-center text-white shrink-0 mr-10 border-[5px] border-white">
                {i + 1}
              </div>

              <div>
                <div className="md:text-xl text-[#2E2E2E] mb-2 font-medium">
                  {step.title}
                </div>
                <div className="md:w-[530px] text-[#737373] text-sm md:text-base">
                  {step.description}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const WhyWeOfferThis = () => {
  const offers = [
    "Identify the best courses and programs for your career goals.",
    "Understand the benefits and features of ise.",
    "Craft a learning plan that fits your schedule and needs.",
    "Gain clarity about our courses, admission process, or answer any questions you might have.",
  ];

  const Tick = () => {
    return (
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16" cy="16" r="15.5" stroke="#FFF6D6" />
        <path
          d="M9 17L13 21L23 11"
          stroke="#FFD952"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };

  return (
    <section className="bg-[#664EFE] flex justify-between py-16 px-5 md:px-[80px]">
      <div className="text-white w-[644px]">
        <p className="text-[#FFD952] tracking-[5%] font-medium text-[13px] w-[]">
          IT'S FREE!
        </p>

        <h2 className="text-[49px]">Why we offer this</h2>

        <p className="mb-7">
          At iṣẹ School, we understand that choosing the right career path and
          educational program can be a challenge. So, we offer personalised
          consultations to help you make informed decisions. Our program
          advisors assist you to:
        </p>

        <div className="flex flex-col gap-y-3">
          {offers.map((offer) => (
            <div className="flex items-center gap-x-4">
              <Tick />
              <span>{offer}</span>
            </div>
          ))}
        </div>

        <div className="w-[208px] mt-14">
          <Button type="tertiary">
            <a href="https://calendar.app.google/UxPkWgnsb8kApR8B8">
              Schedule free call
            </a>
          </Button>
        </div>
      </div>

      <div className="h-[649px] w-[644px] bg-gray-100 rounded-[10px] shrink-0">
        <img
          src={IMAGE_WHAT_WE_NEED}
          className="rounded-[10px]"
          alt="why-we-offer"
        />
      </div>
    </section>
  );
};

const ProgramAdvisor = () => {
  return (
    <Layout>
      <Hero />

      <HowItWorks />

      <WhyWeOfferThis />

      <HomePageAffordable />
    </Layout>
  );
};

export default ProgramAdvisor;
